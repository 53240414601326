import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [2];

export const dictionary = {
		"/": [5],
		"/(protected)/0/acquisitions": [6,[2],[3]],
		"/(protected)/0/acquisitions/referrals": [~7,[2],[3]],
		"/(protected)/0/acquisitions/referrals/create": [~8,[2],[3]],
		"/(protected)/0/home": [9,[2],[3]],
		"/(protected)/0/marketing/qrCodes": [~10,[2],[3]],
		"/(protected)/0/marketing/qrCodes/[id]": [~11,[2],[3]],
		"/(protected)/0/team/accountabilityChart": [~12,[2],[3]],
		"/(protected)/0/team/callins/create": [~13,[2],[3]],
		"/(protected)/0/team/suggestions/create": [~14,[2],[3]],
		"/auth/login": [~16,[],[4]],
		"/auth/login/verifyCookies": [17,[],[4]],
		"/auth/logout": [~18,[],[4]],
		"/domain.com": [19],
		"/getpaid": [~20],
		"/listings": [~21],
		"/offer.fergmar.com": [22],
		"/(public)/privacyPolicy": [15],
		"/rentalAvailability": [~23],
		"/testRoute": [24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';